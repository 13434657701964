import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import Board from "../components/boardgame/board.module.scss";
import Space from "../components/boardgame/space";
import Footer from "../components/footer";
import Metatags from "../components/metatags";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Metatags title="Frontendopoly | May 3 | CSS Grid a day" description="An example of using CSS grid every day, from May 1–31st, 2019." thumbnail="https://cssgrid31.brett.cool/opengraph/may-3.png" url="https://cssgrid31.brett.cool" pathname="/may-3" mdxType="Metatags" />
    <div className={Board.canvas}>
  <div className={Board.grid}>
    <div className={Board.branding}>
      <div className={Board.logo}>Frontendopoly</div>
    </div>
    <div className={`${Board.corner_space} ${Board.corner_space_topleft}`} />
    <Space position="top" color="red" price="3kb" mdxType="Space">
      linear-gradient
    </Space>
    <Space position="top" color="red" price="3kb" mdxType="Space">
      radial-gradient
    </Space>
    <Space position="top" color="red" price="3kb" mdxType="Space">
      @keyframes
    </Space>
    <Space position="top" color="#FFF100" price="2kb" mdxType="Space">
      transition
    </Space>
    <Space position="top" color="#FFF100" price="2kb" mdxType="Space">
      transform
    </Space>
    <Space position="top" color="#FFF100" price="2kb" mdxType="Space">
      animation
    </Space>
    <div className={`${Board.corner_space} ${Board.corner_space_topright}`} />
    <Space position="left" color="#FF8E00" price="1mb" mdxType="Space">
      box-shadow
    </Space>
    <Space position="right" color="#00B64E" price="2kb" mdxType="Space">
      Gatsby
    </Space>
    <Space position="left" color="#FF8E00" price="1mb" mdxType="Space">
      text-shadow
    </Space>
    <Space position="right" color="#00B64E" price="2kb" mdxType="Space">
      Web components
    </Space>
    <Space position="left" color="#FF8E00" price="1mb" mdxType="Space">
      border-radius
    </Space>
    <Space position="right" color="#00B64E" price="2kb" mdxType="Space">
      SVG
    </Space>
    <Space position="left" color="#EC1F99" price="3mb" mdxType="Space">
      inline-block;
    </Space>
    <Space position="right" color="#00B64E" price="2kb" mdxType="Space">
      Houdini
    </Space>
    <Space position="left" color="#EC1F99" price="3mb" mdxType="Space">
      block;
    </Space>
    <Space position="right" color="#0073C1" price="1kb" mdxType="Space">
      Flexbox
    </Space>
    <Space position="left" color="#EC1F99" price="3mb" mdxType="Space">
      inline;
    </Space>
    <Space position="right" color="#0073C1" price="1kb" mdxType="Space">
      Css Grid
    </Space>
    <div className={`${Board.corner_space} ${Board.corner_space_bottomleft}`} />
    <Space position="bottom" color="#99E2FE" price="4mb" mdxType="Space">
      clearfix
    </Space>
    <Space position="bottom" color="#99E2FE" price="4mb" mdxType="Space">
      float right
    </Space>
    <Space position="bottom" color="#99E2FE" price="4mb" mdxType="Space">
      float left
    </Space>
    <Space position="bottom" color="#A0502E" price="5mb" mdxType="Space">
      spacer gif
    </Space>
    <Space position="bottom" color="#A0502E" price="5mb" mdxType="Space">
      display: table
    </Space>
    <Space position="bottom" color="#A0502E" price="5mb" mdxType="Space">
      *zoom: 0;
    </Space>
    <div className={`${Board.corner_space} ${Board.corner_space_bottomright}`} />
  </div>
    </div>
    <Footer date={3} prev={true} next={true} mdxType="Footer">
      <p>{`In trying to come up with ideas to play around with CSS Grid, an unexpected novel idea came up—representing a boardgame.`}</p>
      <p>{`In looking for grids everywhere, you start to `}<em parentName="p">{`see`}</em>{` them everywhere. Looking at Monopoly, I started to discern the grid it was based on. I simplified it a little, because I was running out of ideas for spaces, and because it’s hard to display on small screens.`}</p>
      <p>{`I cheated with the corners a little too using SVG images. Transforming and positioning text is a nightmare (thankful for the admittedly not-widely supported `}<a parentName="p" {...{
          "href": "https://developer.mozilla.org/en/docs/Web/CSS/writing-mode"
        }}>{`writing-mode`}</a>{` property).`}</p>
    </Footer>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      